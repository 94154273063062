<template>
  <div class="vHistoryBlockHeader">
    <div
      class="vHistoryBlockHeader__switcher"
      @click="useFilter('weak')"
    >
      <label
        for="day"
        class="vHistoryBlockHeader__switcher-label"
        :class="{ active: filter === 'weak' && period === null }"
      >
        Неделя
      </label>
    </div>
    <div
      class="vHistoryBlockHeader__switcher"
      @click="useFilter('month')"
    >
      <label
        for="month"
        class="vHistoryBlockHeader__switcher-label"
        :class="{ active: filter === 'month' && period === null }"
      >
        Месяц
      </label>
    </div>
    <div class="vHistoryBlockHeader__period">
      <div
        class="vHistoryBlockHeader__period-label"
        :class="{ active: period }"
      >
        {{ period ? convertUTC : 'Выбрать период' }}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 10L12 14L16 10"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <vDatePicker
        v-model="period"
        @closed="blur"
      />
    </div>

    <div
      v-if="filter !== '' || periodValue !== ''"
      class="vHistoryBlockHeader__period-reset"
    >
      <button @click="resetPeriod">
        Сбросить
      </button>
    </div>
    <div
      v-if="USER_DATA.role_user === 'support'"
      class="vHistoryBlockHeader__dropdown"
      @click.stop="toggleDropdown"
    >
      Статус
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10L12 14L16 10"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <ul
        v-if="dropdown"
        class="vHistoryBlockHeader__dropdown-list"
        :class="{ disabled: filterLoading }"
      >
        <li
          v-for="(sortItem, idx) in sortList"
          :key="sortItem.id"
          class="vHistoryBlockHeader__dropdown-item"
          @click.stop="selectSort(idx, sortItem.active)"
        >
          <div
            class="vHistoryBlockHeader__dropdown-checkbox"
            :class="{ active: sortItem.active }"
          />
          {{ sortItem.label }}
        </li>
      </ul>
    </div>
    <vLightPreloader v-if="preloader" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vDatePicker from '@/components/v-date-picker.vue'
import { convertUtc } from '@/utils/convertUtc'
import { filterWeak, filterMonth, filterPeriod } from '@/utils/filterDate'
import vLightPreloader from '@/components/v-light-preloader'
export default {
  name: 'VHistoryBlockHeader',
  components: {
    vDatePicker,
    vLightPreloader,
  },
  props: {
    sortList: {
      type: Array,
      required: false,
      default: () => [],
    },
    filterLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    watchPeriod: {
      type: Boolean,
      required: true,
    },
    periodValue: {
      type: String,
      default: '',
    },
    filterDateStart: {
      type: String,
      default: '',
    },
    filterDateEnd: {
      type: String,
      default: '',
    },
  },
  emits: [
    'selectFilter',
    'filterTransaction',
    'onWatchPeriod',
    'onPeriodValue',
    'onFilterDateStart',
    'onFilterDateEnd',
    'resetValues',
  ],
  data() {
    return {
      period: null,
      filter: '',
      preloader: false,
      dropdown: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
    convertUTC() {
      return this.period ? convertUtc(this.period) : ''
    },
  },
  watch: {
    async period() {
      if (this.period) {
        this.preloader = true

        this.$emit('onWatchPeriod', true)
        this.$emit('onPeriodValue', this.convertUTC)

        const dates = filterPeriod(this.period)

        this.$emit('selectFilter', {
          date_start: dates.startDate,
          date_end: dates.endDate,
        })

        await this.FETCH_TRANSACTIONS({
          token: this.GET_AUTHENTIFICATED,
          date_start: dates.startDate,
          date_end: dates.endDate,
          count: '',
          page: 1,
        })
        this.preloader = false
      } else {
        this.$emit('onWatchPeriod', false)
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown)
    document
      .querySelectorAll('.vHistoryBlockHeader__period input')
      .forEach((e) => (e.readOnly = true))
  },
  unmounted() {
    document.removeEventListener('click', this.closeDropdown)
  },
  methods: {
    ...mapActions('transactions', ['FETCH_TRANSACTIONS']),
    blur() {
      document.querySelector(':focus').blur()
    },
    selectSort(idx, status) {
      const data = {
        idx,
        status,
      }
      // const filterArr = this.sortList.filter(i => i.active)
      this.$emit('filterTransaction', data)
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown
    },
    closeDropdown() {
      this.dropdown = false
    },
    async useFilter(val) {
      this.$emit('resetValues')
      this.preloader = true
      this.filter = val
      this.period = null
      this.$emit('selectFilter', val)
      let filterData = null

      switch (val) {
        case 'weak':
          filterData = filterWeak()
          break
        case 'month':
          filterData = filterMonth()
          break
        default:
          break
      }

      await this.FETCH_TRANSACTIONS({
        token: this.GET_AUTHENTIFICATED,
        date_start: filterData.startDate,
        date_end: filterData.endDate,
        count: '',
        page: 1,
      })
      this.$emit('onFilterDateStart', filterData.startDate)
      this.$emit('onFilterDateEnd', filterData.endDate)
      this.preloader = false
    },
    async resetPeriod() {
      if (this.periodValue !== '' || this.filter !== '') {
        this.preloader = true
        this.period = null
        this.filter = ''
        this.$emit('resetValues')

        const weakData = filterWeak()
        await this.FETCH_TRANSACTIONS({
          token: this.GET_AUTHENTIFICATED,
          date_start: weakData.startDate,
          date_end: weakData.endDate,
          count: '',
          page: '',
        })
        this.preloader = false
      } else {
        this.period = null
        this.filter = ''
        this.$emit('resetValues')
      }
    },
  },
}
</script>
<style lang="scss">
.vHistoryBlockHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: 300px;
  }
  @media (max-width: 370px) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__switcher {
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 370px) {
      margin-bottom: 16px;
    }
    &-label {
      cursor: pointer;
      padding: 6px 8px;
      font-size: 15px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      margin-right: 12px;
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
      }
    }
  }
  &__period {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 769px) {
      margin-top: 16px;
    }
    @media (max-width: 370px) {
      margin-top: 0;
    }
    &-label {
      cursor: pointer;
      padding: 2px 8px;
      font-size: 15px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
        svg {
          stroke: #fff;
        }
      }
    }
    svg {
      stroke: #80869a;
    }
  }
  &__period-reset {
    button {
      width: 115px;
      height: 30px;
      color: #ec4e4e;
      background: #fff6f6;
      border: 1px solid #ec4e4e;
      border-radius: 12px;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      cursor: pointer;
      @media (max-width: 769px) {
        margin-top: 16px;
      }
    }
  }
  &__dropdown {
    margin-top: 16px;
    cursor: pointer;
    padding: 2px 8px;
    font-size: 15px;
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    position: relative;
    display: flex;
    align-items: center;
    &-list {
      position: absolute;
      left: 0;
      top: calc(100% + 2px);
      margin: 0;
      padding: 10px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      background: #fff;
      &.disabled {
        & * {
          opacity: 0.4;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 999;
          cursor: default;
        }
      }
    }
    &-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 5px 0;
    }
    &-checkbox {
      width: 14px;
      height: 14px;
      border: 1.5px solid #dcdee3;
      margin-right: 15px;
      border-radius: 4px;
      &.active {
        background-image: url(~@/assets/images/checkbox.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    svg {
      stroke: #80869a;
    }
  }
}
</style>
