import axios, { AxiosResponse } from 'axios';

class ReportService {
  async download(
    fromDate: string,
    fromTime: string,
    beforeDate: string,
    beforeTime: string
  ): Promise<Blob> {
    const { data }: AxiosResponse<Blob> = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/statistic/transaction-statistic-for-period`,
      {
        params: {
          from_date: fromDate,
          from_time: fromTime,
          before_date: beforeDate,
          before_time: beforeTime,
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userTokenData'),
        },
        responseType: 'blob',
      }
    );
    return data;
  }
}

export default new ReportService();
