<template>
  <div
    class="reportAdmin"
  >
    <div class="reportAdmin__fieldset">
      <b>Отчет</b>
      <p>
        Здесь вы можете выгрузить отчет по операциям вашего заведения.
        Для этого нужно указать период выгрузки отчета. Отчет может выгружаться за период не более 31 дня. <br>
        В выгруженном отчете у вас будет два листа:
      </p>
      <ol>
        <li>Оплаты — лист, где будут отображены все оплаты за указанный период.</li>
        <li>Возвраты — лист, где будут отображены все возвраты за указанный период.</li>
      </ol>
    </div>
    <div
      class="reportAdmin__container"
      :class="{ halfWidth: singleDay }"
    >
      <div class="reportAdmin__checkbox">
        <VCheckbox
          id="checkYandex"
          :value="singleDay"
          :size="20"
          justify-content="flex-start"
          fill
          label="Выбрать только один день"
          position-label="end"
          @click="changeCheckbox"
          @clickHandler="changeCheckbox"
        />
      </div>
      <div
        class="reportAdmin__calendar"
        :class="{ halfWidth: singleDay }"
      >
        <!-- Дата начала -->
        <div class="reportAdmin__field">
          <span v-if="!fromDate">Выберите дату начала</span>
          <div
            v-else
            class="reportAdmin__time-block"
          >
            Дата начала
            <div class="reportAdmin__time">
              {{ filterDate(fromDate) }}
            </div>
          </div>
          <img src="@/assets/images/calendar.svg">
          <VDatePicker
            v-model="fromDate"
            :not-range="true"
            :disabled-date="activeDates"
          />
        </div>

        <!-- Время начала -->
        <div
          v-show="!singleDay"
          class="reportAdmin__field"
        >
          <div class="reportAdmin__time-block">
            Выберите время начала
            <div class="reportAdmin__time">
              {{ fromTime }}
            </div>
          </div>
          <img src="@/assets/images/clock.svg">
          <VDatePicker
            v-model="fromTime"
            type="time"
            value-type="format"
            :not-range="true"
            format="HH:mm"
            popup-class="time"
          />
        </div>

        <!-- Дата окончания -->
        <div
          v-show="!singleDay"
          class="reportAdmin__field"
        >
          <span v-if="!beforeDate">Выберите дату окончания</span>
          <div
            v-else
            class="reportAdmin__time-block"
          >
            Дата окончания
            <div class="reportAdmin__time">
              {{ filterDate(beforeDate) }}
            </div>
          </div>
          <img src="@/assets/images/calendar.svg">
          <VDatePicker
            v-model="beforeDate"
            :not-range="true"
            :disabled-date="disabledbeforeDate"
          />
        </div>

        <!-- Время окончания -->
        <div
          v-show="!singleDay"
          class="reportAdmin__field"
        >
          <div class="reportAdmin__time-block">
            Выберите время окончания
            <div class="reportAdmin__time">
              {{ beforeTime }}
            </div>
          </div>
          <img src="@/assets/images/clock.svg">
          <VDatePicker
            v-model="beforeTime"
            type="time"
            value-type="format"
            :not-range="true"
            format="HH:mm"
            popup-class="time"
          />
        </div>
      </div>
      <VButton
        :disabled="disableBtn"
        @click="downloadReport"
      >
        Выгрузить отчет
      </VButton>

      <vLightPreloader
        v-show="isLoading"
        :fixed="false"
        :transparent="true"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import ReportService from '@/services/reportTS'
import VCheckbox from '@/components/v-checkbox.vue'
import VDatePicker from '@/components/v-date-picker.vue'
import VButton from '@/components/v-button.vue'
import vLightPreloader from '@/components/v-light-preloader'

const singleDay = ref(false),
  isLoading = ref(false),
  fromDate = ref(null),
  beforeDate = ref(null),
  fromTime = ref('00:00'),
  beforeTime = ref('23:59')

const disableBtn = computed(() => singleDay.value && !fromDate.value || (!singleDay.value && !beforeDate.value || !singleDay.value && !fromDate.value))

const filterDate = (date) => {
  const dateConvert = new Date(date);
  const options = { year: "2-digit", month: "numeric", day: "numeric" };
  return dateConvert.toLocaleDateString('ru-RU', options)
}

const changeCheckbox = () => {
  singleDay.value = !singleDay.value
}

const disabledbeforeDate = (date) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const setFromDate = new Date(fromDate.value)
  const calculateDate = setFromDate.setDate(setFromDate.getDate() + 30)
  return date < new Date(fromDate.value) || date > new Date(calculateDate) || today < date

}

const activeDates = (date) => {
  const today = new Date()
  const today2 = today.setHours(0, 0, 0, 0)
  return today2 < date
}

const downloadReport = async () => {
  isLoading.value = true
  try {
    const response = await ReportService.download(
      fromDate.value,
      singleDay.value ? '00:00' : fromTime.value,
      singleDay.value ? fromDate.value : beforeDate.value,
      singleDay.value ? '23:59': beforeTime.value
    )

    const url = URL.createObjectURL(
      new Blob([response], {
        type: 'application/vnd.ms-excel',
      })
    )
    const fileNamePeriod = `отчет с ${filterDate(fromDate.value)} по ${filterDate(beforeDate.value)}`
    const fileNameDay = `отчет за ${filterDate(fromDate.value)}`
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${singleDay.value ? fileNameDay : fileNamePeriod} .xlsx`)
    document.body.appendChild(link)
    link.click()
    isLoading.value = false
  } catch (e) {
    console.log(e)
  }
}

watch([fromDate], () => {
  if (beforeDate.value) {
    const from = new Date(fromDate.value)
    const to = new Date(beforeDate.value)
    if (from > to) {
      beforeDate.value = null
    }
  }
})

</script>

<style lang="scss" scoped>
.reportAdmin {
  position: relative;
  &__container {
      &.halfWidth {
    width: 50%;
  }
  }
  &__checkbox {
    margin-bottom: 36px;
  }
  &__calendar {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px 16px;
    grid-template-areas:
      ". ."
      ". .";
      @media(max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 16px 0;
        grid-template-areas:
          "."
          "."
          "."
          ".";
      }
      &.halfWidth {
        display: flex;
      }
  }
  &__field {
    position: relative;
    border: 1px solid #E6E7EB;
    border-radius: 12px;
    height: 52px;
    padding: 17px;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80869A;
    justify-content: space-between;
    width: 100%;
  }
  &__time-block {
    display: inline-flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #80869A;
  }
  &__time {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #010D35;
  }
  .mx-datepicker {
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    cursor: pointer;
    :deep(.mx-input-wrapper) {
      height: 100%;
    }
    :deep(input) {
      cursor: pointer;
        height: 100%;
    }
    .mx-icon-clear {
      display: none;
    }
    .mx-icon-calendar {
      display: none;
    }
  }
  &__fieldset {
    position: absolute;
    left: calc(100% + 56px);
    width: 295px;
    top: 0;
    @media(max-width: 1170px) {
      position: static;
      width: 100%;
      margin-bottom: 20px;
    }
    b {
      color: #010D35;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      display: block;
      margin-bottom: 12px;
    }
    p {
      color: #4E5672;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    }
    ol {
      margin: 0;
      color: #4E5672;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      padding-left: 18px;
    }
  }
}
</style>
